import React from 'react'
import SEO from '../components/SEO'
import PageTitle from '../components/PageTitle'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

const ServicesPage = ({ data }) => (
  <>
    <SEO
      title="Professional and comprehensives services in the construction business"
      keywords={[
        'kitchen renovation',
        'kitchen ideas 2020',
        'carpentry near me',
        'carpentry',
        'kitchen rebuilding',
        'new construction',
        'home constructions',
        'home interior design',
        'woodworking',
        'carpentry near me'
      ]}
      description="Kitchen renovation, professional services and home interior design"
    />
    <div className="mt-20 md:mt-32"></div>
    <PageTitle>Services</PageTitle>
    <div className="h-12 md:hidden"></div>
    <div className={'container'}>
      <div className="bg-gray-100 lg:py-6 lg:flex lg:justify-center mb-32 lg:h-80 lg:mb-0">
        <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:shadow ">
          <div className="lg:w-1/2">
            <Img
              style={{ height: '100%' }}
              fluid={data.custom.childImageSharp.fluid}
            />
          </div>
          <div className="py-12 px-6 max-w-xl lg:max-w-5xl lg:w-1/2 text-center flex flex-col justify-center">
            <h2 className="text-3xl text-gray-800 font-bold ">
              Custom Woodworking
            </h2>
            <p className="mt-4 text-gray-600">
              Get quality, custom options for every element of your home
              renovation. From elegant libraries to entertainment spaces to
              outdoor kitchens that will not only raise your property's value,
              but make it the talk of the town.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 lg:py-6 lg:flex lg:justify-center lg:h-80 mb-32 lg:mb-0">
        <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:shadow">
          <div className="lg:w-1/2 lg:hidden">
            <Img
              style={{ height: '100%' }}
              fluid={data.kitchen.childImageSharp.fluid}
            />
          </div>
          <div className="py-12 px-6 max-w-xl lg:max-w-5xl lg:w-1/2 text-center flex flex-col justify-center">
            <h2 className="text-3xl text-gray-800 font-bold ">Kitchens</h2>
            <p className="mt-4 text-gray-600">
              You deserve the kitchen of their dream. With Matys Carpentry, you
              can make that dream a reality with fairly priced, locally sourced,
              and expertly crafted renovations and additions.
            </p>
          </div>
          <div className="lg:w-1/2 hidden lg:block">
            <Img
              fluid={data.kitchen.childImageSharp.fluid}
              style={{ height: '100%' }}
            />
          </div>
        </div>
      </div>
      <div className="bg-gray-100 lg:py-6 lg:flex lg:justify-center lg:h-80">
        <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:shadow">
          <div className="lg:w-1/2">
            <Img
              fluid={data.bath.childImageSharp.fluid}
              style={{ height: '100%' }}
            />
          </div>
          <div className="py-12 px-6 max-w-xl lg:max-w-5xl lg:w-1/2 text-center flex flex-col justify-center">
            <h2 className="text-3xl text-gray-800 font-bold ">Bathrooms</h2>
            <p className="mt-4 text-gray-600">
              Whether you want another or to enhance your existing bathroom,
              Matys Carpentry can offer you high-end and completely bespoke for
              a luxury experience every time you go.
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
)

export const query = graphql`
  {
    kitchen: file(name: { eq: "kitchen_17" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    custom: file(name: { eq: "custom_18" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bath: file(name: { eq: "bath_5" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ServicesPage
