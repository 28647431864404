import React from 'react'

export default function PageTitle({ title, description, children }) {
  if (!title && !children) return null

  return (
    <header className="page-title text-center my-6">
      <h1 className="text-black  font-bold leading-loose text-center m-0">
        {title || children}
      </h1>

      {description && <p className="text-xl">{description}</p>}
    </header>
  )
}
